.searchbox-overall {
    border-radius: 10px !important;
}

.overall-search-icon {
    color: black !important;
}

.overall-search-position {
    /* padding-right: 15rem !important; */
}

.input-icons i {
    position: absolute;
}

.input-icons {
    width: 100%;
    margin-bottom: 10px;
}

.icon {
    padding-top: 9px;
    padding-right: 13px;
    color: black;
    min-width: 50px;
    text-align: center;
}

.input-field {
    width: 40%;
    padding: 10px;
    padding-left: 36px;
    border: 0;
    border-radius: 10px;
    background: #FFFFFF;
    caret-color: #FF0000;
}

.input-field:focus {
    outline: none;
}

.search-close-position {
    padding-left: 10px;
    margin-bottom: -3px;
    cursor: pointer;
}

.video-play-button-search {
    height: 60px !important;
}

.search-watch-position {
    padding-top: 10px;
}

.search-watch-position-enabled {
    padding-top: 0;
}

.topic-description {
    color: white !important;
}

/* .custom-text-width {
    width: 60% !important;
} */


/* MOBILE */
@media only screen and (min-width: 600px) {
    .custom-text-width {
        width: 30% !important;
    }
}