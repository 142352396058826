.wrapper {
	overflow: hidden;
}

.text-center {
	text-align: center !important;
}

.logo {
	border-radius: 50%;
}

.mui-textfield {
	background-color: white;
	/* border-radius: 5px; */
}

.float-left {
	font-size: 12px;
	text-decoration: none;
	float: left !important;
}

.float-right {
	font-size: 12px;
	text-decoration: none;
	float: right !important;
}

.font-12px {
	font-size: 12px;
}

.padding-top-10px {
	padding-top: 10px !important;
	font-weight: 0 !important;
}

.btn-create-account {
	width: 50%;
	background-color: rgba(69, 69, 69, 0.3) !important;
}

.btn-create-account:hover {
	background-color: #454545 !important;
}

.btn {
	font-weight: 100 !important;
	border-radius: 10px !important;
}

.login-logo {
	width: 38%;
}

/* MuiInputBase-input MuiFilledInput-input css-1rv476z-MuiInputBase-input-MuiFilledInput-input */

.inputRounded .MuiOutlinedInput-input:-webkit-autofill {
	/* -webkit-box-shadow: 0 0 0 100px #454545 inset !important;
	-moz-box-shadow: 0 0 0 100px #454545 inset !important; */
	/* border-radius: 10px 10px 10px 10px !important; */
	border-radius: 10px !important;
	font-size: 13px !important;
}

.inputRounded .MuiFilledInput-input:-webkit-autofill {
	/* -webkit-box-shadow: 0 0 0 100px #454545 inset !important;
	-moz-box-shadow: 0 0 0 100px #454545 inset !important; */
	/* border-radius: 10px 10px 10px 10px !important; */
	/* border-radius: 10px !important; */
	border-radius: 10px !important;
	font-size: 13px !important;
}

.inputRounded .MuiInputBase-input:-webkit-autofill {
	/* -webkit-box-shadow: 0 0 0 100px #454545 inset !important;
	-moz-box-shadow: 0 0 0 100px #454545 inset !important; */
	/* border-radius: 10px 10px 10px 10px !important; */
	border-radius: 10px !important;
	font-size: 13px !important;
}

/* .input-password-field .MuiInputBase-adornedEnd {
	border-radius: 20px;
} */