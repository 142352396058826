.wrapper {
	overflow: hidden;
}

.main-header {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 28px;
	line-height: 34px;
}

.about-modal {
	background-color: #0D0D0D !important;
	border: 1px solid #FFFFFF !important;
	border-radius: 0 !important;
}

.btn-submit-signup {
	background-color: '#FF0000' !important;
	width: 171px !important;
	border-radius: 5px !important;
}

.about-modal-custom-size {
	max-width: 650px !important;
}