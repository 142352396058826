.theme {
  font-size: 18px;
  text-align: center;
  border-bottom: 1px solid #ccc;
  padding: 10px 0px;
  margin-bottom: 10px;
  font-weight: bold;
  background-color: #ffffff;
}

.media.media-video {
  margin-bottom: 10px;
}

.media.media-video .thumb {
  position: relative;
  width: 200px;
  margin-right: 10px;
  text-decoration: none;
}

.media.media-video .thumb img {
  width: 100%;
}

.media.media-video .thumb .duration {
  position: absolute;
  display: block;
  bottom: 5px;
  right: 5px;
  color: #fff;
  background-color: #000;
  font-size: 0.8rem;
  padding: 4px;
  -webkit-transition: 0.2s linear opacity;
}

.media.media-video .thumb:hover .duration {
  opacity: 0.3;
}

.media.media-video .media-body .media-heading {
  font-size: 1.2rem;
  color: #454545;
  text-decoration: none;
}

.media.media-video .media-body .media-heading:hover {
  color: #000;
}

.media.media-video .media-body .meta {
  color: #999;
}

.media.media-video .media-body .meta a {
  color: #777;
}

.media.media-video .media-body .desc {
  color: #999;
}


/*  input range  */
/* input[type="range"] {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  width: 100%;
  height: 20px;
  margin: 0;
  border: none;
  padding: 1px 2px;
  border-radius: 14px;
  
  color: rgb(196, 196, 196);
  box-shadow: inset 0 1px 0 0 #0d0e0f, inset 0 -1px 0 0 #3a3d42;
  -webkit-box-shadow: inset 0 1px 0 0 #0d0e0f, inset 0 -1px 0 0 #3a3d42;
  outline: none;
}

input[type="range"]::-moz-range-track {
  border: inherit;
  background: transparent;
}

input[type="range"]::-ms-track {
  border: inherit;
  color: transparent; 
  background: transparent;
}

input[type="range"]::-ms-fill-lower,
input[type="range"]::-ms-fill-upper {
  background: transparent;
}

input[type="range"]::-ms-tooltip {
  display: none;
}


input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 40px;
  height: 18px;
  border: none;
  border-radius: 12px;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #529de1), color-stop(100%, #245e8f)); 
  background-image: -webkit-linear-gradient(top , #529de1 0, #245e8f 100%); 
  background-image: linear-gradient(to bottom, #529de1 0, #245e8f 100%);
}

input[type="range"]::-moz-range-thumb {
  width: 40px;
  height: 18px;
  border: none;
  border-radius: 12px;
  background-image: linear-gradient(to bottom, #529de1 0, #245e8f 100%);
}

input[type="range"]::-ms-thumb {
  width: 40px;
  height: 18px;
  border-radius: 12px;
  border: 0;
  background-image: linear-gradient(to bottom, #529de1 0, #245e8f 100%);
}

input[type="range"][disabled] { 
  cursor: not-allowed; 
}
 */

/* .form-control:focus {
    background-color: #003469 !important;
} */

.custom-video-control {
  width: 100% !important;
  /* margin-top: 7px !important; */
}

.custom-video-duration {
  margin-top: 7px !important;
}

.suggested-video-layout {
  background-color: white;
  color: black;
}

.nav-item {
  cursor: pointer;
}

iframe {
  /* display: block !important; */
  /* iframes are inline by default */
  /* height: 100vh !important; */
  /* Set height to 100% of the viewport height */
  /* width: 100vw !important; */
  /* Set width to 100% of the viewport width */
  border: none !important;
}

.ytp-watermark .yt-uix-sessionlink {
  visibility: none;
}


.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  /* padding-top: 100%; */
  /* Player ratio: 100 / (1280 / 720); */
}

.iframe-content-bottom-play {
  position: absolute;
  top: 440px;
  color: white;
  background-color: #0d0d0d;
  width: 100%;
  height: 52px;
}

.iframe-content-bottom-pause {
  position: absolute;
  top: 0 !important;
  color: white;
  background-color: #0d0d0d;
  width: 100%;
  height: 100%;
}

.iframe-content-top {
  position: absolute;
  top: 0;
  /* left: 15px; */
  color: white;
  background-color: #0d0d0d;
  width: 100%;
  height: 58px;
}

.player-wrapper .iframe-content-bottom {
  display: initial;
  z-index: 1;
}

.player-wrapper:hover {
  /* height: 100000px !important; */
}

.custom-range {
  height: 40px !important;
}

.video-timeline {
  height: 40px !important;
}

.volume-seeker {
  width: 70px;
}

.text-black {
  color: black !important;
}

.img-cursor {
  cursor: pointer;
}

.testBg101 {
  background-color: red;
  height: 100%;
  width: 100%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.player-test {
  pointer-events: none
}

.slidecontainer {
  width: 100%;
}

/* .slider {
  -webkit-appearance: none;
  -webkit-transition: .2s;
  width: 100%;
  height: 2px;
  border-radius: 5px;
  background: #dedede;
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
} */

/* #FF0000 */
/* .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04AA6D;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04AA6D;
  cursor: pointer;
} */

.video-slider {
  padding: 0.5rem;
}

.btn-play-video-responsive {
  color: white;
  padding-top: 21px;
}

.btn-text-responsive {
  color: white;
  padding-top: 23px;
  padding-left: 8px;
}


.duration-responsive {
  padding-top: 23px;
}

.btn-play-video {
  color: white;
  margin: 0.8rem;
}

.btn-play-video-icon {
  height: 1000px;
}

.fa-play-responsive {
  /* margin-right: 1rem; */
}

.volume-content {
  width: 10rem !important;
}

.grid-right {
  margin: auto !important;
  margin-right: 0 !important;
}

.text-duration {
  /* margin-top: 18px !important; */
}

.play-rate-active {
  color: #FF0000 !important;
}

.video-information {
  margin: 1rem;
}

.video-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
}


.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-container iframe {
  pointer-events: none;
}

.video-container iframe {
  position: absolute;
  top: -60px;
  left: 0;
  width: 100%;
  height: calc(100% + 120px);
}

.video-foreground {
  pointer-events: none;
}
















/* YT CUSTOM VIDEO SLIDER */

/* === range theme and appearance === */
input[type="range"] {
  font-size: 1.5rem;
  transition: transform 0.3s ease-in-out;
  width: 100%;
}

input[type="range"] {
  transition: transform 0.3s ease-in-out;
  color: rgb(255, 0, 0);
  --thumb-height: 1.125em;
  --track-height: 0.125em;
  --track-color: rgba(0, 0, 0, 0.2);
  --brightness-hover: 180%;
  --brightness-down: 80%;
  --clip-edges: 0.125em;
}

input[type="range"].win10-thumb {
  transition: transform 0.3s ease-in-out;
  color: rgb(255, 0, 0);
  --thumb-height: 1.3rem;
  --thumb-width: 1.3rem;
  --clip-edges: 0.0125em;
}

@media (prefers-color-scheme: dark) {
  input[type="range"] {
    color: rgb(255, 0, 0);
    --track-color: rgba(255, 255, 255, 0.1);
  }

  input[type="range"].win10-thumb {
    color: rgb(255, 0, 0);
  }
}

/* === range commons === */
input[type="range"] {
  transition: transform 0.3s ease-in-out;
  position: relative;
  background: #fff0;
  overflow: hidden;
}

input[type="range"]:active {
  cursor: grabbing;
}

input[type="range"]:disabled {
  filter: grayscale(1);
  opacity: 0.3;
  cursor: not-allowed;
}

/* === WebKit specific styles === */
input[type="range"],
input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* transition: transform 0.3s ease-in-out; */
  transition: all ease 100ms;
  height: var(--thumb-height);
}

input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
  position: relative;
}

input[type="range"]::-webkit-slider-thumb {
  --thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
  --clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
  --clip-bottom: calc(var(--thumb-height) - var(--clip-top));
  --clip-further: calc(100% + 1px);
  --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0 100vmax currentColor;

  width: var(--thumb-width, var(--thumb-height));
  background: linear-gradient(currentColor 0 0) scroll no-repeat left center / 50% calc(var(--track-height) + 1px);
  background-color: currentColor;
  box-shadow: var(--box-fill);
  border-radius: var(--thumb-width, var(--thumb-height));

  filter: brightness(100%);
  clip-path: polygon(100% -1px,
      var(--clip-edges) -1px,
      0 var(--clip-top),
      -100vmax var(--clip-top),
      -100vmax var(--clip-bottom),
      0 var(--clip-bottom),
      var(--clip-edges) 100%,
      var(--clip-further) var(--clip-further));
}

input[type="range"]:hover::-webkit-slider-thumb {
  filter: brightness(var(--brightness-hover));
  cursor: grab;
}

input[type="range"]:active::-webkit-slider-thumb {
  filter: brightness(var(--brightness-down));
  cursor: grabbing;
}

input[type="range"]::-webkit-slider-runnable-track {
  background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center / 100% calc(var(--track-height) + 1px);
}

input[type="range"]:disabled::-webkit-slider-thumb {
  cursor: not-allowed;
}

/* === Firefox specific styles === */
input[type="range"],
input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-thumb {
  appearance: none;
  transition: all ease 100ms;
  height: var(--thumb-height);
}

input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-thumb,
input[type="range"]::-moz-range-progress {
  background: #fff0;
}

input[type="range"]::-moz-range-thumb {
  background: currentColor;
  border: 0;
  width: var(--thumb-width, var(--thumb-height));
  border-radius: var(--thumb-width, var(--thumb-height));
  cursor: grab;
}

input[type="range"]:active::-moz-range-thumb {
  cursor: grabbing;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  background: var(--track-color);
}

input[type="range"]::-moz-range-progress {
  appearance: none;
  background: currentColor;
  transition-delay: 30ms;
}

input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-progress {
  height: calc(var(--track-height) + 1px);
  border-radius: var(--track-height);
}

input[type="range"]::-moz-range-thumb,
input[type="range"]::-moz-range-progress {
  filter: brightness(100%);
}

input[type="range"]:hover::-moz-range-thumb,
input[type="range"]:hover::-moz-range-progress {
  filter: brightness(var(--brightness-hover));
}

input[type="range"]:active::-moz-range-thumb,
input[type="range"]:active::-moz-range-progress {
  filter: brightness(var(--brightness-down));
}

input[type="range"]:disabled::-moz-range-thumb {
  cursor: not-allowed;
}