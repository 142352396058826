.wrapper {
	overflow: hidden;
}

/* MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1jgdtkb-MuiInputBase-root-MuiOutlinedInput-root */
/* MuiSelect-select MuiSelect-filled MuiInputBase-input MuiFilledInput-input css-nh22sz-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input */
.select-program .MuiSelect-filled {
	background-color: #454545 !important;
	border-radius: 10px !important;
	border: 0 !important;
}

.select-program .MuiSelect-select {
	/* background-color: transparent !important; */
}