.modal-bg,
.search-modal-bg,
.profile-modal-bg,
.about-modal-bg {
    position: fixed;
    /* Stay in place */
    /*display: none;*/
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
}

.modal-bg,
.profile-modal-bg,
.about-modal-bg {
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.5);
    /* Black w/ opacity */
}

/** modal container **/
.modal-container {
    box-shadow: 0px 4px 16px 10px rgba(30, 33, 45, 0.3);
    border-radius: 8px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.modal-container.dark {
    background: #1F1E21;
}

.modal-container.light {
    background: #fff;
}

.modal-container .close {
    position: absolute;
    top: 25px;
    right: 25px;
}


/** SEARCH MODAL **/
.search-modal-bg {
    /* background: rgba(31, 30, 33, 0.91); */
    background: rgba(13, 14, 11, 0.75);
}

.search-modal-bg .search-close {
    display: flex;
    justify-content: flex-end;
}

.search-header p {
    font-size: 14px;
}

.search-bar-group {
    display: flex;
    justify-content: center;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}

.search-bar-group input {
    width: 70%;
}

.search-bar-group button {
    width: 30%;
}

.search-bar-group input,
.search-bar-group button {
    border-radius: 0px;
}

.search-results .img-default {
    width: 100%;
    max-width: 664px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.spinner-container {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    /*display: none;*/
}

.spinner-container .support-text {
    text-align: center;
    display: block;
}


/** SEARCH RESULTS PER ROW & SUBJECT VIDEOS PER ROW **/
.search-results-per-row {
    /*display: none;*/
}

.search-results-item,
.subject-videos-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.search-results-item {
    max-width: 900px;
    margin: 0 auto;
}

.search-results-item .search-keywords {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.search-results-item .search-keywords .search-for {
    font-size: 18px;
    color: #A7A7A7;
    letter-spacing: 0px;
}

.search-results-item .search-keywords .search-count {
    font-size: 14px;
    color: #A7A7A7;
    letter-spacing: 0px;
}

.search-results-item .topic,
.subject-videos-row .topic {
    margin: 5px;
    max-width: 210px;
    outline: none !important;
    background-color: #151515;
    border-radius: 8px;
}

.search-results-item .topic {
    flex: 1 0 calc(25% - 10px);
}

.search-results-item .topic-hover:hover,
.subject-videos-row .topic-hover:hover {
    cursor: pointer;
    transition: 0.8s ease;
    transform: scale(1.1);
    z-index: 3;
    box-shadow: 0px 4px 16px 10px rgba(30, 33, 45, 0.3);
}

.topic-low-opacity {
    opacity: 0.5;
}

.topic-lock:hover {
    cursor: not-allowed !important;
}

.topic-lock-icon {
    z-index: 1;
    height: 50px;
    width: 50px;
}

.mouse-invalid:hover {
    cursor: not-allowed !important;
}

.search-results-item .topic:hover,
.subject-videos-row .topic-lock:hover {
    cursor: pointer;
}

.search-results-item .topic .thumbnail,
.subject-videos-row .topic .thumbnail {
    position: relative;
}

.search-results-item .topic .thumbnail img,
.subject-videos-row .topic .thumbnail img {
    width: 100%;
    height: auto;
    border-radius: 8px 8px 0px 0px;
}

.search-results-item .topic .thumbnail .topic-play,
.subject-videos-row .topic .thumbnail .topic-play {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
}

.search-results-item .topic .topic-details .topic-1st-details,
.subject-videos-row .topic .topic-details .topic-1st-details {
    display: flex;
    justify-content: space-between;
}

.search-results-item .topic .topic-details .topic-1st-details .topic-duration,
.subject-videos-row .topic .topic-details .topic-1st-details .topic-duration {
    font-size: 10px;
    color: #A7A7A7;
    letter-spacing: 0px;
}

.search-results-item .topic .topic-details .topic-description,
.subject-videos-row .topic .topic-details .topic-description {
    font-size: 14px;
    overflow: hidden;
    color: #A7A7A7;
    letter-spacing: 0px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin: 0;
}

@media only screen and (max-width: 900px) {
    .search-results-item {
        max-width: 675px;
    }

    .search-results-item .topic {
        flex: 1 0 calc(33.3% - 10px);
        max-width: 31%;
    }
}

@media only screen and (max-width: 640px) {
    .search-modal-bg .search-close {
        display: inline-block;
        float: right;
        position: relative;
        top: 25px;
        right: 15px;
        padding: 0px !important;
    }

    .loadingio-spinner-spinner-eyk1pv8nm5w {
        margin-top: -60px !important;
    }

    .search-results-item .search-keywords {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .search-results-item .search-keywords .search-for {
        font-size: 12px;
        width: 50%;
    }

    .search-results-item .search-keywords .search-count {
        text-align: right;
        font-size: 12px;
        width: 50%;
    }

    .search-container {
        padding-left: 15px !important;
        padding-right: 15px !important;
        padding-top: 180px;
    }

    .search-results {
        margin-top: 40px;
    }

    .search-results-item {
        max-width: 430px;
    }

    .search-results-item .topic {
        flex: 1 0 calc(50% - 10px);
        max-width: 50%;
    }

    .search-results-item .topic .topic-details .topic-description {
        font-size: 12px;
    }

    .input-field {
        width: 80% !important;
    }
}


/* SUBJECT MODAL */
.subject-modal {
    max-width: 750px;
}

.cover-photo img {
    width: 100%;
    height: auto;
    border-radius: 8px 8px 0px 0px;
}

.subject-description p {
    font-size: 14px;
    color: #A7A7A7;
    letter-spacing: 0px;
}

.sort-buttons {
    display: flex;
    justify-content: flex-end;
}

.sort-list {
    margin-right: 3px;
}

.subject-videos {
    max-height: 580px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: #EC4646 transparent;
    scrollbar-width: auto;
    position: relative;
    margin: 0 auto;
}


/*** RED SCROLLBAR **/
/* width */
.subject-videos::-webkit-scrollbar,
.details-container-scrollable::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.subject-videos::-webkit-scrollbar-track,
.details-container-scrollable::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
.subject-videos::-webkit-scrollbar-thumb,
.details-container-scrollable::-webkit-scrollbar-thumb {
    /*background: #EC4646; */
    background: #A20000;
    border-radius: 15px;
}

/* Handle on hover */
.subject-videos::-webkit-scrollbar-thumb:hover,
.details-container-scrollable::-webkit-scrollbar-thumb:hover {
    background: #EC4646;
    cursor: pointer;
}

/** SUBJECT VIDEO PER LIST **/
.subject-videos-list {
    display: none;
}

.subject-videos-list .topic {
    display: flex;
    /* background-color: #151515; */
    border-radius: 5px;
    position: relative;
    /* max-width: 625px;
    margin-left: auto;
    margin-right: auto; */
}

.subject-videos-list .topic-hover:hover {
    /* cursor: pointer; */
    transition: 0.4s ease;
    transform: scale(1.03);
    z-index: 3;
    box-shadow: 0px 0px 0px 0px rgba(30, 33, 45, 0.5);
}

.subject-videos-list .thumbnail {
    position: relative;
    width: 160px;
    /* height: 110px; */
}

.subject-videos-list .thumbnail img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.subject-videos-list .thumbnail .topic-play {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
}

.subject-videos-list .topic-details {
    flex: 1;
}

.subject-videos-list .topic-details .topic-description,
.subject-videos-list .topic-details .topic-duration {
    color: #FFFF;
    letter-spacing: 0px;
}

.subject-videos-list .topic-details .topic-1st-details {
    display: flex;
    justify-content: space-between;
}

.subject-videos-list .topic-details .topic-duration {
    font-size: 11px;
}

.subject-videos-list .topic-details .topic-description {
    font-size: 18px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin: 0;
}

/** SUBJECT VIDEO PER ROW **/
.subject-videos-row .topic {
    flex: 1 0 calc(33.3% - 10px);
}

@media only screen and (max-width: 640px) {

    /* width */
    .subject-videos::-webkit-scrollbar {
        width: 5px;
    }

    .modal-container .close {
        right: 15px;
    }

    .subject-modal .subject-description {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .subject-modal .subject-videos {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .subject-modal .subject-description p {
        text-align: justify;
        font-size: 12px;
    }

    .subject-videos-row .topic .topic-details .topic-description,
    .subject-videos-list .topic-details .topic-description {
        font-size: 12px;
    }

    /** SUBJECT VIDEO PER LIST **/
    .subject-videos-list .thumbnail {
        display: flex;
        align-items: center;
        width: 40%;
        height: auto;
    }

    .subject-videos-list .thumbnail .topic-play {
        top: 25%;
    }

    .subject-videos-list .topic-details {
        padding: 10px 10px 10px 5px !important;
    }

    /** SUBJECT VIDEO PER ROW **/
    .subject-videos-row .topic {
        flex: 1 0 calc(50% - 10px);
        max-width: 50%;
    }
}

@media only screen and (max-width: 330px) {
    .subject-videos-list .thumbnail .topic-play {
        top: 20%;
    }

    .subject-videos-row .thumbnail .topic-play {
        top: 25% !important;
    }
}



/** PROFILE MODAL **/
.profile-modal {
    max-width: 700px;
}

.profile-modal select {
    background-position: 98% center;
}

.btn-edit-profile,
.btn-update-profile {
    width: 172px;
}

.btn-update-profile {
    display: none;
}

@media only screen and (max-width: 640px) {
    .profile-modal {
        margin-top: 85px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .profile-modal .form-flex {
        display: block;
    }

    .profile-modal .form-flex .form-group-w-label {
        width: 100%;
    }
}



/** ABOUT MODAL **/
.about-modal {
    max-width: 700px;
    background-color: #fff;
    /*display: none;*/
}

.about-modal .main-header {
    font-size: 20px;
}

.header-w-bg {
    text-align: center;
    background-color: #F1EFEF;
}

.header-w-bg h4,
.text-main-title {
    font-size: 18px;
}

.details-container-scrollable {
    max-height: 580px;
    overflow-y: auto;
}

.details-container-reference-scrollable {
    max-height: 640px !important;
    overflow-y: auto;
}

.details-container {
    font-size: 14px;
    color: #838383;
    letter-spacing: 1px;
    line-height: 22px;
}

.details-container .sub-title {
    font-size: 16px;
    color: #838383;
}

.details-container ul {
    margin-left: 17px;
    padding-left: 0px;
}

.details-container ul li.circle-transparent {
    list-style-type: circle;
}

.details-container p {
    white-space: pre-wrap;
    /* CSS3 */
    white-space: -moz-pre-wrap;
    /* Firefox */
    white-space: -pre-wrap;
    /* Opera <7 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
}

@media only screen and (max-width: 640px) {
    .about-modal {
        margin-top: 0px !important;
        padding-left: 15px !important;
    }

    .details-container .sub-title {
        font-size: 14px;
    }

    .details-container {
        font-size: 12px;
        padding-right: 5px !important;
    }
}

.pointer {
    cursor: pointer;
}