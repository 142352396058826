.watch-video-bgcolor {
  /*background-color: black !important;*/
}

/*video {
	border:1px solid darkgreen;
	width:420px;
	height:231px;
	background:black;
}*/


.art-controls {
  background-color: #0D0D0D;
  opacity: 0.7;
  height: 35px !important;
}

.art-control-time {
  /*background-color: #d43a3a;
	opacity: 0.5;*/
}

.art-control-progress {
  height: 10px !important;
}

.art-progress-indicator {
  height: 20px !important;
  width: 20px !important;
}


.art-bottom {
  padding: 55px 0 0 !important;
}

.hide-scroll {
  overflow: hidden !important;
}

.button-test {
  position: absolute;
  top: 30px;
  left: 25px;
  text-align: left;
  z-index: 20;
}

.art-notice-inner {
  display: none !important;
}

.text-white {
  color: white;
}

.video-header {
  z-index: 0;
  color: white;
  width: 100%;
  opacity: 0;
  transition: all .2s ease-in-out;
  background: rgba(0, 0, 0, 0.5);
  height: 80px;
  position: absolute;
  /*    box-shadow: 3px 13px 13px rgb(0 0 0 / 25%);
    transition: 0.5s ease;*/
}

/*.art-video:hover .video-header1{
	z-index: 21;
	opacity: 1 !important;
    transition: all .2s ease-in-out !important;
    background-color: red;
}*/
.art-video:hover+.hide {
  z-index: 21 !important;
  display: block !important;
  opacity: 1 !important;
  transition: all .2s ease-in-out !important;
  color: red !important;
  background-color: red;
}

.art-hover {
  /*	z-index: 21 !important;
  display: block !important;
	opacity: 1 !important;
	transition: all .2s ease-in-out !important;
  color: red !important;*/
  background-color: red !important;
}

.hide {
  display: none;
}

.close-video {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  z-index: 30;
  color: gray;
  cursor: pointer;
  /*color: #d43a3a;*/
}

.close-video:hover {
  color: white;
  transition: 0.5s;
}

.art-contextmenu-info {
  display: none !important;
}

.art-contextmenu-version {
  display: none !important;
}

/*
.art-video-player{
  width: 100%;
} 

.art-subtitle-show{
  width: 100%;
} 

.art-layer-show{
  width: 100%;
}
*/
.art-control-show {
  width: 100% !important;
}

/*.art-mask-show{
  width: 100%;
}*/
/*art-auto-size*/


video::-internal-media-controls-download-button {
  display: none;
}

video::-webkit-media-controls-enclosure {
  overflow: hidden;
}

video::-webkit-media-controls-panel {
  width: calc(100% + 30px);
  /* Adjust as needed */
}


.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-select-player-text {
  color: white;
}

.MuiTypography-body1 {
  margin: 2rem !important;
}