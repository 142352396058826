.page-bg {
	/* background-color: #171717; */
	height: 100vh;
}

/* NAVBAR */
.main-nav {
	height: 70px;
	background: #101010;
	box-shadow: 6px 3px 8px rgba(21, 20, 20, 0.6);
	display: flex;
	align-items: center;
}

.main-nav .header {
	font-size: 20px;
	margin-top: -5px;
	margin-left: -5px;
}

.main-nav .header .logo {
	width: 43px;
	height: auto;
	top: 13px;
	margin-top: -24px;
}

.main-nav .btn-search {
	margin-left: auto;
}

@media only screen and (max-width: 640px) {
	.main-nav {
		padding-left: 15px !important;
		padding-right: 15px !important;
	}

	.main-nav {
		justify-content: space-between;
	}

	.main-nav .btn-side {
		margin-right: 0 !important;
	}

	.main-nav .btn-search {
		margin-left: 0;
	}
}

/** SIDE NAVIGATION **/
.side-navigation {
	height: 100%;
	width: 275px;
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	overflow-x: hidden;
	background: #000000;
	box-shadow: 3px 13px 13px rgba(0, 0, 0, 0.25);
	transition: 0.5s ease;
	/*margin-left: -275px;*/

}

.side-navigation .txt-username {
	/* font-family: helvetica-bold; */
	font-family: 'Inter' !important;
	letter-spacing: 1px;
	font-size: 25px;
}

.side-navigation ul li.active a {
	/*color: #EC4646 !important;*/
	color: #A20000 !important;
}

.side-navigation ul li a {
	text-decoration: none;
	/* font-family: helvetica-bold; */
	font-family: 'Inter' !important;
	font-size: 18px;
	display: block;
	letter-spacing: 1px;
}

.side-navigation .btn-side-close {
	display: block;
	position: relative;
	top: 0px;
	left: 230px;
}

.sidenav-menu {
	list-style-type: none;
}

.sidenav-menu .about-links {
	transition: 0.5s ease;
	/*display: none;*/
}

.sidenav-menu .about-links a {
	text-decoration: none;
	display: inline-block;
	font-family: 'Inter' !important;
	/* font-family: helvetica-regular; */
	font-size: 16px;
}

.sidenav-menu .about-links a.active {
	color: #FB8C8C !important;
}


/** HOMEPAGE **/
.homepage {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.homepage .subject {
	flex: 1 0 calc(25% - 5px);
	margin: 2.5px;
	max-width: 25%;
	outline: none !important;
}

.homepage .subject:hover {
	outline: none !important;
	outline: 0 !important;
}

.homepage .subject:focus {
	outline: none !important;
	outline: 0 !important;
}

.homepage .subject::-moz-focus-inner {
	border: 0 !important;
	outline: 0 !important;
}

.homepage .subject a img {
	width: 100%;
	height: auto;
	display: block;
	border-radius: 8px;
}

.homepage .subject:hover {
	transition: 0.8s ease;
	transform: scale(1.2);
}

@media only screen and (max-width: 1024px) {
	.homepage .subject {
		flex: 1 0 calc(33.3% - 5px);
		max-width: 33%;
	}

	.homepage .subject:hover {
		transform: scale(1.3);
	}
}

@media only screen and (max-width: 640px) {
	.homepage .subject {
		flex: 1 0 calc(50% - 5px);
		max-width: 50%;
	}

	.homepage .subject:hover {
		transform: scale(1.3);
	}
}