.logo-link {
	color: white;
	text-decoration: none;
}

.cursor-pointer {
	cursor: pointer;
}

.iframe-ref {
	width: 100%;
	height: 70vh;
}

/*EC4646 */

.reference-list-active {
	background-color: #A20000 !important;
	color: white !important;
}

.text-white {}

.text-left {
	text-align: left !important;
}

.pointer-link {
	cursor: pointer !important;
}

.pointer-link:hover {
	text-decoration: underline !important;
}

.eOPJCx {
	display: none;
}

.jZkBQk .wrapper {
	border-radius: 7px !important;
}

.school-disable {
	pointer-events: none;
	opacity: 0.8;
	cursor: no-drop;
}

.school-disable:hover {
	cursor: no-drop;
}

.main-nav {
	background-color: #0D0D0D !important;
}

.MuiPaper-root {
	background-color: #000000 !important;
}

.appbar-profile-menu {
	width: auto;
	height: 28px;
	margin-top: 10px;
	cursor: pointer;
	padding-right: 10px;
}

.custom-nav-link .MuiButton-root {
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	text-transform: capitalize;
	text-decoration: underline;
}

.nav-link .custom-nav-link-active {
	border-bottom: 3px solid red;
	border-radius: 0;
}

.custom-color-dark {
	background: rgba(13, 14, 11, 0.75) !important;
}

.border-rad-5 {
	border-radius: 5px;
}

.text-width-50 {
	/* width: 50% !important; */
}

.form-group .show-change-pw,
.form-group .hide-change-pw {
	position: absolute;
	top: 9px;
	right: 165px;
	z-index: 2;
}

.pl-220 {
	padding-left: 220px;
}

.pr-220 {
	padding-right: 220px;
}

.pl-145 {
	padding-left: 145px;
}

.pr-145 {
	padding-right: 145px;
}

.custom-border-tad {
	background: rgba(13, 14, 11, 0.75) !important;
	border: 0 !important;
	max-width: 700px;
}

.terms-and-condition-header-position {
	float: right;
	padding-right: 70px;
}

.tad-custom-style {
	font-weight: 500;
	font-size: 19px;
	line-height: 29px;
	color: #FFFFFF;
}

.about-active-tab {
	border-bottom: 3px solid red;
}

.about-text-color {
	font-family: 'Inter' !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 19px !important;
	text-align: justify !important;
	color: #FFFFFF !important;

}

.about-email-text {
	color: white;
	text-decoration: none;
}

.about-email-text:hover {
	text-decoration: underline;
}

.user-notification-list-content {
	position: absolute;
	top: 78px;
	right: 40px;
	width: auto;
	border: 1px solid transparent;
	background: rgba(13, 14, 11, 0.75) !important;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 3px;
	z-index: 2;
	height: 25rem;
	overflow-y: auto;
	overflow-x: hidden;
	scrollbar-color: #EC4646 transparent;
	scrollbar-width: auto;
}

.user-notification-list-content::-webkit-scrollbar {
	width: 4px;
}

.user-notification-list-content::-webkit-scrollbar-track {
	background: transparent;
}

.user-notification-list-content::-webkit-scrollbar-thumb {
	background-color: #FF0000;
	/* color of the scroll thumb */
	border-radius: 20px;
	/* roundness of the scroll thumb */
	border: 3px solid #EC4646;
	/* creates padding around scroll thumb */
}

.user-notification-list-main {
	width: 310px;
	/* height: 80px; */
	/* background: #000000; */
}

.user-notification-list-item {
	max-height: 50px;
	display: flex;
	align-items: center;
}

.user-notif-thumbnail {
	height: 50px;
	width: auto;
	align-items: center;
	text-align: center;
	border-radius: 10px;
	/* margin: 0;
	position: absolute;
	top: 50%;
	left: 15%; */
	/* -ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%); */
}

.user-notif-text {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #FFFFFF;
}

.user-notif-text-position {
	padding-top: 21px !important;
}

.notification-border {
	border: 0.5px solid #FFFFFF;
}

.user-notification-parent:hover {
	background-color: #000000;
	cursor: pointer !important;
	transition: 0.6s
}

.user-notification-parent {
	cursor: pointer;
}

.app-bar-custom .MuiContainer-maxWidthXl {
	max-width: 100% !important;
	background-color: #0D0D0D !important;

}

.nav-link-button {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	text-transform: capitalize !important;
	color: #FFFFFF;
}

.MuiMenu-list {
	background-color: rgba(0, 0, 0, 0.75) !important;
}

.navbar-custom-font {
	font-family: 'Inter' !important;
	font-style: normal !important;
	font-weight: 300 !important;
	font-size: 12px !important;
	line-height: 24px !important;
	color: #d6d1d1 !important;
}

.navbar-parent-item:hover {
	border-bottom: 3px solid #FF0000;
}

.profile-modal-bg-width {
	width: 51% !important;
}

.change-password-text {
	font-family: 'Inter' !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 28px !important;
	line-height: 34px !important;
}

.nav-is-active {
	border-bottom: 3px solid #FF0000;
}

.parent-notif-thumb {
	width: 85px !important;
	text-align: center;
}

.topnav {
	padding: 6px;
	cursor: pointer;
}

.topnav a {
	/* float: left; */
	color: white;
	display: block;
	text-align: center;
	text-decoration: none;
	font-size: 14px;
	border-bottom: 3px solid transparent;
	border-radius: 1px;
}

/* .topnav a:hover {
	border-bottom: 3px solid red;
} */

.topnav a.active {
	border-bottom: 3px solid #FF0000;
}