.wrapper {
	overflow: hidden;
}

.logo {
	border-radius: 50%;
}

.forgot-text {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0;
}

/* mobile */
@media only screen and (max-device-width: 480px) {
	.custom-container .form-container {
		width: 502px;
		height: 471px;
	}
}

/* desktop */
@media (min-width:961px) {
	.custom-container .form-container {
		width: 502px;
		height: 500px;
	}
}

.form-container {
	position: relative;

}

.bottom {
	position: absolute;
	bottom: 0;
	left: 0;

}

.inputRounded .MuiFilledInput-input:-webkit-autofill {
	/* -webkit-box-shadow: 0 0 0 100px #454545 inset !important;
	-moz-box-shadow: 0 0 0 100px #454545 inset !important; */
	/* border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important; */
	/* border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important; */
	/* border-radius: 10px 10px 0 0 !important; */
	border-radius: 10px !important;
	font-size: 13px !important;
}