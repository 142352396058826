/******* FONT *******/
body {
	font-family: 'Inter' !important;
	/* font-style: normal; */
	/* font-family: 'Montserrat', sans-serif; */

	color: #151515;
	overflow-x: hidden;
}

.f-regular {
	font-weight: 400;
}

.f-medium {
	font-weight: 500;
}

.f-semibold {
	font-weight: 600;
}

.f-bold {
	font-weight: 700;
}


/** Helvetica **/
/* @font-face {
	font-family: helvetica-regular;
	src: url("../fonts/Helvetica/Helvetica.ttf");
}

@font-face {
	font-family: helvetica-bold;
	src: url("../fonts/Helvetica/Helvetica-Bold.ttf");
} */


/** TEXTS **/
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	margin-block-start: 0px;
	margin-block-end: 0px;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	/* font-family: 'Montserrat', sans-serif; */
	font-family: 'Inter' !important;
}

p {
	letter-spacing: 1px;
	display: block;
	margin-block-start: 0;
	margin-block-end: 0;
	margin: 0;
}

input,
button {
	/* font-family: 'Montserrat', sans-serif; */
	font-family: 'Inter';
}

.text-white {
	color: #fff !important;
}

.text-black-primary {
	color: #151515 !important;
}

.text-gray {
	color: #838383;
}

.text-red {
	/*color: #EC4646;*/
	color: #A20000;
}


/* ALIGNMENTS */
body,
html {
	margin: 0;
	height: 100%;
}

.center-container,
.right-container {
	height: 100%;
	display: flex;
	align-items: center;
	align-content: center;
}

.center-container {
	justify-content: center;
}

.right-container {
	justify-content: flex-end;
}

.text-center {
	text-align: center;
}

.block-center {
	margin-left: auto;
	margin-right: auto;
	display: block;
}


/** MARGIN TOP ***/
.mt-5 {
	margin-top: 5px;
}

.mt-10 {
	margin-top: 10px;
}

.mt-15 {
	margin-top: 15px;
}

.mt-20 {
	margin-top: 20px;
}

.mt-25 {
	margin-top: 25px;
}

.mt-30 {
	margin-top: 30px;
}

.mt-35 {
	margin-top: 35px;
}

.mt-40 {
	margin-top: 40px;
}

.mt-45 {
	margin-top: 45px;
}

.mt-50 {
	margin-top: 50px;
}

.mt-55 {
	margin-top: 55px;
}

.mt-60 {
	margin-top: 60px;
}

.mt-65 {
	margin-top: 65px;
}

.mt-70 {
	margin-top: 70px;
}

.mt-75 {
	margin-top: 75px;
}

.mt-80 {
	margin-top: 80px;
}

.mt-85 {
	margin-top: 85px;
}

.mt-90 {
	margin-top: 90px;
}

.mt-95 {
	margin-top: 95px;
}

.mt-100 {
	margin-top: 100px;
}


/** MARGIN LEFT ***/
.ml-5 {
	margin-left: 5px;
}

.ml-10 {
	margin-left: 10px;
}

.ml-15 {
	margin-left: 15px;
}

.ml-20 {
	margin-left: 20px;
}

.ml-25 {
	margin-left: 25px;
}

.ml-30 {
	margin-left: 30px;
}

.ml-35 {
	margin-left: 35px;
}

.ml-40 {
	margin-left: 40px;
}

.ml-45 {
	margin-left: 45px;
}

.ml-50 {
	margin-left: 50px;
}

.ml-55 {
	margin-left: 55px;
}

.ml-60 {
	margin-left: 60px;
}

.ml-65 {
	margin-left: 65px;
}

.ml-70 {
	margin-left: 70px;
}

.ml-75 {
	margin-left: 75px;
}

.ml-80 {
	margin-left: 80px;
}

.ml-85 {
	margin-left: 85px;
}

.ml-90 {
	margin-left: 90px;
}

.ml-95 {
	margin-left: 95px;
}

.ml-100 {
	margin-left: 100px;
}


/** MARGIN RIGHT ***/
.mr-5 {
	margin-right: 5px;
}

.mr-10 {
	margin-right: 10px;
}

.mr-15 {
	margin-right: 15px;
}

.mr-20 {
	margin-right: 20px;
}

.mr-25 {
	margin-right: 25px;
}

.mr-30 {
	margin-right: 30px;
}

.mr-35 {
	margin-right: 35px;
}

.mr-40 {
	margin-right: 40px;
}

.mr-45 {
	margin-right: 45px;
}

.mr-50 {
	margin-right: 50px;
}

.mr-55 {
	margin-right: 55px;
}

.mr-60 {
	margin-right: 60px;
}

.mr-65 {
	margin-right: 65px;
}

.mr-70 {
	margin-right: 70px;
}

.mr-75 {
	margin-right: 75px;
}

.mr-80 {
	margin-right: 80px;
}

.mr-85 {
	margin-right: 85px;
}

.mr-90 {
	margin-right: 90px;
}

.mr-95 {
	margin-right: 95px;
}

.mr-100 {
	margin-right: 100px;
}


/** MARGIN BOTTOM ***/
.mb-5 {
	margin-bottom: 5px;
}

.mb-10 {
	margin-bottom: 10px;
}

.mb-15 {
	margin-bottom: 15px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-25 {
	margin-bottom: 25px;
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-35 {
	margin-bottom: 35px;
}

.mb-40 {
	margin-bottom: 40px;
}

.mb-45 {
	margin-bottom: 45px;
}

.mb-50 {
	margin-bottom: 50px;
}

.mb-55 {
	margin-bottom: 55px;
}

.mb-60 {
	margin-bottom: 60px;
}

.mb-65 {
	margin-bottom: 65px;
}

.mb-70 {
	margin-bottom: 70px;
}

.mb-75 {
	margin-bottom: 75px;
}

.mb-80 {
	margin-bottom: 80px;
}

.mb-85 {
	margin-bottom: 85px;
}

.mb-90 {
	margin-bottom: 90px;
}

.mb-95 {
	margin-bottom: 95px;
}

.mb-100 {
	margin-bottom: 100px;
}


/*** PADDING TOP ***/
.pt-5 {
	padding-top: 5px;
}

.pt-10 {
	padding-top: 10px;
}

.pt-15 {
	padding-top: 15px;
}

.pt-20 {
	padding-top: 20px;
}

.pt-25 {
	padding-top: 25px;
}

.pt-30 {
	padding-top: 30px;
}

.pt-35 {
	padding-top: 35px;
}

.pt-40 {
	padding-top: 40px;
}

.pt-45 {
	padding-top: 45px;
}

.pt-50 {
	padding-top: 50px;
}

.pt-55 {
	padding-top: 55px;
}

.pt-60 {
	padding-top: 60px;
}

.pt-65 {
	padding-top: 65px;
}

.pt-70 {
	padding-top: 70px;
}

.pt-75 {
	padding-top: 75px;
}

.pt-80 {
	padding-top: 80px;
}

.pt-85 {
	padding-top: 85px;
}

.pt-90 {
	padding-top: 90px;
}

.pt-95 {
	padding-top: 95px;
}

.pt-100 {
	padding-top: 100px;
}

/*** PADDING BOTTOM ***/
.pb-5 {
	padding-bottom: 5px;
}

.pb-10 {
	padding-bottom: 10px;
}

.pb-15 {
	padding-bottom: 15px;
}

.pb-20 {
	padding-bottom: 20px;
}

.pb-25 {
	padding-bottom: 25px;
}

.pb-30 {
	padding-bottom: 30px;
}

.pb-35 {
	padding-bottom: 35px;
}

.pb-40 {
	padding-bottom: 40px;
}

.pb-45 {
	padding-bottom: 45px;
}

.pb-50 {
	padding-bottom: 50px;
}

.pb-55 {
	padding-bottom: 55px;
}

.pb-60 {
	padding-bottom: 60px;
}

.pb-65 {
	padding-bottom: 65px;
}

.pb-70 {
	padding-bottom: 70px;
}

.pb-75 {
	padding-bottom: 75px;
}

.pb-80 {
	padding-bottom: 80px;
}

.pb-85 {
	padding-bottom: 85px;
}

.pb-90 {
	padding-bottom: 90px;
}

.pb-95 {
	padding-bottom: 95px;
}

.pb-100 {
	padding-bottom: 100px;
}


/*** PADDING LEFT ***/
.pl-5 {
	padding-left: 5px;
}

.pl-10 {
	padding-left: 10px;
}

.pl-15 {
	padding-left: 15px;
}

.pl-20 {
	padding-left: 20px;
}

.pl-25 {
	padding-left: 25px;
}

.pl-30 {
	padding-left: 30px;
}

.pl-35 {
	padding-left: 35px;
}

.pl-40 {
	padding-left: 40px;
}

.pl-45 {
	padding-left: 45px;
}

.pl-50 {
	padding-left: 50px;
}

.pl-55 {
	padding-left: 55px;
}

.pl-60 {
	padding-left: 60px;
}

.pl-65 {
	padding-left: 65px;
}

.pl-70 {
	padding-left: 70px;
}

.pl-75 {
	padding-left: 75px;
}

.pl-80 {
	padding-left: 80px;
}

.pl-85 {
	padding-left: 85px;
}

.pl-90 {
	padding-left: 90px;
}

.pl-95 {
	padding-left: 95px;
}

.pl-100 {
	padding-left: 100px;
}


/*** PADDING RIGHT ***/
.pr-5 {
	padding-right: 5px;
}

.pr-10 {
	padding-right: 10px;
}

.pr-15 {
	padding-right: 15px;
}

.pr-20 {
	padding-right: 20px;
}

.pr-25 {
	padding-right: 25px;
}

.pr-30 {
	padding-right: 30px;
}

.pr-35 {
	padding-right: 35px;
}

.pr-40 {
	padding-right: 40px;
}

.pr-45 {
	padding-right: 45px;
}

.pr-50 {
	padding-right: 50px;
}

.pr-55 {
	padding-right: 55px;
}

.pr-60 {
	padding-right: 60px;
}

.pr-65 {
	padding-right: 65px;
}

.pr-70 {
	padding-right: 70px;
}

.pr-75 {
	padding-right: 75px;
}

.pr-80 {
	padding-right: 80px;
}

.pr-85 {
	padding-right: 85px;
}

.pr-90 {
	padding-right: 90px;
}

.pr-95 {
	padding-right: 95px;
}

.pr-100 {
	padding-right: 100px;
}


/*** HEADER **/
.header .logo {
	display: inline-block;
	position: relative;
	top: 18px;
	margin-top: -18px;
	margin-right: 8px;
}

.header {
	color: #151515;
	font-size: 30px;
}


a:hover,
a:active,
a:focus {
	outline: none !important;
	outline: 0 !important;
}

/** SPAN ICONS **/
span:hover {
	cursor: pointer;
}




/** INPUT FORM **/
.form-flex {
	display: flex;
	justify-content: space-between;
}

.form-flex .form-group-w-label {
	width: calc(50% - 5px);
}

.form-group-w-label {
	font-size: 14px;
	letter-spacing: 1px;
}

.field-label {
	color: #fff;
	font-size: 16px;
}

.input-form {
	max-height: 36px;
	font-size: 14px;
	border-radius: 3px;
	border: 1px solid #D5D4D4;
	padding: 10px 15px;
	width: 100%;
	box-sizing: border-box;
	letter-spacing: 1px;
	color: #151515;
	transition: 0.2s ease;
}

.input-form:focus {
	outline: none !important;
	border: 1px solid #b7b4b4;
}

.input-form::-webkit-input-placeholder {
	/* Edge */
	color: #aaaaaa;
}

.input-form:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #aaaaaa;
}

.input-form::placeholder {
	color: #aaaaaa;
}

input:disabled,
select:disabled {
	background-color: #D5D4D4;
}

input:disabled:hover,
select:disabled:hover {
	cursor: not-allowed;
}

select:disabled {
	opacity: 1 !important;
}


/* required */
.form-group {
	position: relative;
}

.label-required {
	top: 9px;
	left: 15px;
	position: absolute;
	font-size: 14px;
	letter-spacing: 1px;
	color: #AAAAAA;
}

.label-required .star {
	color: #EC4646;
}

/** dropdown **/
.dropdown select {
	width: 100%;
	max-height: 36px;
	font-size: 14px;
	border-radius: 3px;
	border: 1px solid #D5D4D4;
	padding: 9px 14px;
	color: #151515;
	letter-spacing: 1px;
	/* font-family: 'Montserrat', sans-serif; */
	font-family: 'Inter' !important;
}

select {
	outline: none !important;
	background: url("../images/icon-dropdown.svg") no-repeat 96% center;
	background-color: #fff;
	-moz-appearance: none;
	-webkit-appearance: none;
}

select::-ms-expand {
	display: none;
}



/** BUTTON FORM **/
.btn {
	height: 36px;
	width: 100%;
	border-radius: 3px;
	/*background-color: #EC4646;*/
	/* background-color: #A20000; */
	background-color: #FF0000;
	color: #fff;
	font-weight: 700;
	font-size: 14px;
	outline: none !important;
	border: 0px;
	transition: 0.2s ease;
}

.btn:hover {
	background-color: #d43a3a;
	cursor: pointer;
}

.btn:hover {
	outline: none !important;
	outline: 0 !important;
}

.btn:focus {
	outline: none !important;
	outline: 0 !important;
}

.btn::-moz-focus-inner {
	border: 0 !important;
	outline: 0 !important;
}


/** IMAGE **/
img {
	user-drag: none;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}


/** RESPONSIVE ***/
@media only screen and (max-width: 640px) {
	.main-container {
		padding-left: 15px !important;
		padding-right: 15px !important;
	}
}

@media only screen and (min-width: 400px) and (max-width: 640px) {
	.header {
		font-size: 25px;
	}
}

@media only screen and (max-width: 399px) {
	.header {
		font-size: 20px;
	}
}