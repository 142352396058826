.modal-show-all-subject-title {
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 40px;
    color: #FFFFFF;
}

.modal-show-all-subject-title-mobile {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 40px;
    color: #FFFFFF;
}

.modal-show-all-watched-status {
    float: left;
    font-size: 12px;
    padding-top: 15px;
}

.modal-show-all-search {
    float: right;
    font-size: 12px;
    padding-top: 6px;
}


.modal-show-all-status-color {
    color: #44CC66;
}

.modal-show-all-sort {
    height: 24px;
    float: right;
}

.modal-show-all-search .MuiButton-root {
    min-width: 0 !important;
}


.video-zoom {
    cursor: pointer;
    transition: transform .4s;
}

.video-zoom:hover {
    cursor: pointer;
    -ms-transform: scale(1.3);
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    z-index: 1;
}

.modal-show-all-searchbox {
    float: right;
}

.inner-addon {
    position: relative;
}

/* style icon */
.inner-addon .fa-search {
    position: absolute;
    padding: 8px;
    pointer-events: none;
}

.inner-addon .fa-times {
    position: absolute;
    padding: 7px;
    /* left: 13.3rem; */
    width: 11%;
    right: 0px !important;
    bottom: 3px;
}

/* align icon */
.left-addon .fa-search {
    left: 0px;
}

.right-addon .fa-times {
    right: 0px;
}

/* add padding  */
.left-addon input {
    padding-left: 30px;
    padding-right: 30px;
}

.right-addon input {
    padding-right: 30px;
}

.custom-searchbox {
    height: 34px;
    background-color: black;
    border: 0;
    color: white;
    caret-color: #FF0000;
}

.clear-searchbox:hover {
    cursor: pointer;
}

.clear-searchbox {
    /* z-index: 10; */
    flex: 1;
}

.modal-show-all-grid-video-parent .MuiGrid-root {
    max-width: 310px !important;
    text-align: left !important;
}

.modal-show-all-duration {
    text-align: left;
    font-weight: 500;
    font-size: 10px;
    line-height: 19px;
}

.watch-disabled {
    /* padding-top: 12px; */
}

.watch-disabled:hover {
    cursor: not-allowed;
}

.watch {
    /* padding-top: 0 !important; */
}

.topic-play-v2 {
    height: 55px !important;
    width: auto !important;
}


.img-thumb-overlay-v2 {
    position: relative;
    width: 100%;
    /* max-width: 400px; */
}

.video-thumb-landscape-v2 {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 5px;
}

.video-thumb-landscape-mobile-v2 {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 5px;
}

.img-icon-overlay-v2 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
}

.img-icon-v2 {
    font-size: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}