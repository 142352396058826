/*** STEP BY STEP PROGRESS BAR **/
.step-by-step-container {
	width: 100%;
	max-width: 775px;
	margin-left: auto;
	margin-right: auto;
	display: block;
}
.step-by-step-container ul{
	padding: 0px;
	text-align: center;
}
.progressbar {
  counter-reset: step;
}
.progressbar li {
  list-style: none;
  display: inline-block;
  width: 30.33%;
  position: relative;
  text-align: center;
  cursor: pointer;
}
.progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 45px;
  height: 45px;
  line-height : 46px;
  border: 7px solid #A7A7A7;
  border-radius: 100%;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  background-color: #fff;
  font-size: 20px;
  font-weight: bold;
  color: #A7A7A7;
}
.progressbar li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 7px;
  background-color: #A7A7A7;
  top: 25px;
  left: -40%;
  z-index : -1;
}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.active:before {
  background-color: #A7A7A7;
  color: #fff;
} 
.progressbar li.success:before {
  background-color: #219742;
  color: transparent;
  border-color: #219742;
  background-image: url("../images/icon-step-check.svg");
  background-repeat: no-repeat;
  background-position: center;
} 
.progressbar li.success + li:after {
  background-color: #219742;
}
.progressbar .step-icon{
	display: block;
	margin-top: -150px;
}
.progressbar .step-icon img{
	width: auto;
	height: 35px;
}
.progressbar .step-label{
	color: #fff;
	font-size: 14px;
	letter-spacing: 1px;
}
.progressbar .step-label.text-success{
	color: #219742;
	font-weight: bold;
}
.progressbar .step-label br{
	display: none;
}
.progressbar .step-label .dummy-text{
	color: transparent;
	 -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
@media only screen and (max-width: 640px) {
	.progressbar .step-icon{
		display: block;
		margin-top: -165px;
	}
	.progressbar .step-label.step-profile{
		position: relative;
    	top: 10px;
	}
   .progressbar .step-label br{
		display: block;
	}
}
@media only screen and (max-width: 400px) {
	.progressbar .step-label{
		font-size: 12px;
	}
}



/***TERMS & AGREEMENT **/
.terms-conditions-step-by-step{
	display: block;
}
.terms-condition-footer{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.terms-condition-footer .agree-checkbox{
	margin-left: -5px;
}
.terms-condition-footer .agree-checkbox label{
	font-size: 14px;
	color: #4F4F4F;
	position: relative;
	top: -2px;
}
.btn-submit-terms{
	width: 172px;
}
@media only screen and (max-width: 640px) {
	.terms-condition-footer{
		display: block;
	}
	.btn-submit-terms{
		margin-top: 25px;
	}
}

/** CHANGE PASSWORD ***/
.re-enter-password-step-by-step{
	margin: 0 auto;
	/*display: none;*/
}

/*** UPDATE PROFILE ***/
.profile-modal-step-by-step{
	/*display: none;*/
	background-color: transparent !important;
	position: relative !important;
}