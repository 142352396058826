.wrapper {
	overflow: hidden;
}

.logo {
	border-radius: 50%;
}

.input-text-field .MuiInputBase-adornedEnd {
	border-radius: 20px;
}

.MuiFormControl-root {
	width: 100%;
}

.inputRounded .MuiOutlinedInput-input:-webkit-autofill {
	/* -webkit-box-shadow: 0 0 0 100px #454545 inset !important;
	-moz-box-shadow: 0 0 0 100px #454545 inset !important; */
	/* border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important; */
	/* border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important; */
	/* border-radius: 10px 10px 10px 10px !important; */
	font-size: 13px !important;
}