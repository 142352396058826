.link-no-underline{
	text-decoration: none;
}

.white{
	color: white;
}

.primary {
	color: #EC4646;
}

.topic-details{
	cursor: default !important;
}