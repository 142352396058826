.page-bg {
    /* background-image: url("home-cover.jpg") !important;
    background-repeat: no-repeat, repeat !important;
    background-color: #0D0D0D !important;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 500px !important; */
}


.subject-recent-watch {
    border-radius: 5px;
}


/* mobile */
/* @media only screen and (min-width: 600px) {
    .subject-watch-cover {
        width: 100% !important;
        height: 100% !important;
    }
} */

/* desktop */
/* @media (min-width:768px) {
    .subject-watch-cover {
        width: 240px !important;
        height: 140px !important;
    }
} */


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .subject-watch-cover {
        width: 100% !important;
        height: 100% !important;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .subject-watch-cover {
        width: 100% !important;
        height: 100% !important;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .subject-watch-cover {
        width: 240px !important;
        height: 140px !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .subject-watch-cover {
        width: 240px !important;
        height: 140px !important;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .subject-watch-cover {
        width: 240px !important;
        height: 140px !important;
    }
}

.recently-section .btn-recently {
    width: 140px !important;
    height: 36px !important;
    font-weight: 600 !important;
    border-radius: 10px !important;
    background-color: #454545 !important;
}

.recently-section .btn-white {
    width: 80px;
    background-color: white;
    border: 0;
    color: black;
    font-weight: 600;
    border-radius: 10px !important;
    text-transform: none !important;
}

.recently-section .btn-white:hover {
    background-color: white;
}

.recently-section .btn-white:hover {
    border: 0 !important;
}

.subject-header {
    font-style: normal;
    font-weight: 800;
    font-size: 17px;
    /* line-height: 36px; */
}

.subject-video-list {
    height: 206px !important;
}

.video-name-label {
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    text-align: center;
}

.MuiImageList-root {
    overflow: visible !important;
}

.subject-videos-section .subject-list {
    max-width: 20% !important;
}

.MuiImageListItem-root {
    width: 136px !important;
    border-radius: 5px;
}

.video-thumb-landscape {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 5px;
}

.video-thumb-landscape-mobile {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 5px;
}

.reference-thumb-landscape {
    width: 6.5rem;
    height: 10.5rem;
    display: block;
    border-radius: 5px;
}

.mb-82 {
    margin-bottom: 82px;
}

.mb-13 {
    margin-bottom: 13px;
}

.mb-1 {
    margin-bottom: 1px;
}

.grid-video-parent .MuiGrid-item {
    /* padding-left: 3px !important; */
}


/* .slick-slide {
    margin: 0 10px;
}

.slick-list {
    margin: 0 -27px;
} */

.slick-slide>div {
    margin: 0 5px;
}

.slick-list {
    margin: 0 -5px;
}

.subject-header {
    font-size: 19px !important;
    font-family: 'Inter';
    font-style: normal;
}

.show-all {
    color: red;
    font-size: 12px;
    transition: 0.3s;
}

.show-all:hover {
    cursor: pointer;

}

.subject-video-zoom {
    cursor: pointer;
    transition: transform .4s;
}

.grid-video-parent .MuiGrid-root {
    max-width: 310px !important;
}

.subject-video-zoom:hover {
    cursor: pointer;
    -ms-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    z-index: 1;
}

.overlay {
    height: 100%;
    width: 100%;
    opacity: 0.3;
    transition: .3s ease;
    background-color: transparent;
}

.play-icon {
    color: black;
    font-size: 2rem;
    cursor: pointer;
}

.video-play-button {
    height: 50px;
    width: 50px;
}

.video-play-button-mobile {
    height: 50px;
    width: 50px;
}

/* .fa-play {
    position: absolute;
} */

.block-icon {
    position: relative;
    display: inline-flex;
}

/* .icon-tag {
    position: absolute;
    top: 33px;
    right: 84px;
}

.icon-tag-mobile {
    position: absolute;
    top: 26px;
    right: 64px;
} */


.container1 {
    position: relative;
}

.container1 img {
    display: block;

}

.container1 .icon-tag {
    position: absolute;
    /* bottom: 34%;
    left: 35%; */
    /* bottom: 40%;
    left: 32%; */
    bottom: 50%;
    left: 35%;
}

/* HOMEPAGE ICON PLAY */
/* .container1 .icon-tag2 {
    position: absolute;
    bottom: 35%;
    left: 40%;
} */


/* mobile */
@media only screen and (min-width: 320px) {
    .container1 .icon-tag2 {
        position: absolute;
        bottom: 50% !important;
        left: 33% !important;
    }
}

/* desktop*/
@media (min-width:961px) {
    .container1 .icon-tag2 {
        position: absolute;
        bottom: 35% !important;
        left: 40% !important;
    }
}



/* SHOW ALL ICON PLAY */
.container1 .icon-tag3 {
    position: absolute;
    bottom: 34%;
    left: 35%;
}

.container1 .icon-tag4 {
    position: absolute;
    bottom: 34%;
    left: 30%;
}

.modal-subject-name {
    font-weight: 500;
    font-size: 20px;
    line-height: 34px;
    color: #FFFFFF;
}

.modal-close {
    width: 27px;
    height: 27px;
}

.close {
    cursor: pointer;
}

.modal-show-all {
    /* opacity: 0; */
    /* transition: visibility 0s 2s, opacity 2s linear; */
}

.modal-show-all {
    z-index: 2;
}

.video-thumb-size {
    /* height: 150px; */
}

.br-5 {
    border-radius: 5px;
}

.font-16 {
    font-size: 16px !important;
}

.subject-text-box {
    text-shadow: 2px 2px black;
    cursor: pointer;
    text-decoration: none;
}

.text-decoration-none {
    text-decoration: none !important;
}

.img-thumb-overlay-v2 {
    position: relative;
    width: 100%;
    /* max-width: 400px; */
}

.video-thumb-landscape-v2 {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 5px;
}

.video-thumb-landscape-mobile-v2 {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 5px;
}

.img-icon-overlay-v2 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
}

.img-icon-v2 {
    font-size: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}