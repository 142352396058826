/** FORM **/
.form-bg {
	background: url("../images/login-img.jpg") no-repeat center center fixed;
	height: 100vh;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

.form-step-by-step {
	background-color: '#0D0D0D';
	height: 100vh;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

.form-container {
	max-width: 420px;
	width: 420px;
	border-radius: 10px;
	box-sizing: border-box;
	transition: 0.2s ease;
	/* box-shadow: 0px 2px 12px rgba(236, 70, 70, 0.45); */
	box-shadow: 0px 0px 22px 7px #1664B4;
	background-color: rgba(0, 0, 0, 0.9) !important;
}


/** LOGIN FORM **/
.link-forgot-pw {
	font-size: 14px;
	text-align: right;
	display: block;
}

.login-form {
	background-color: #1F1E21;
}

.form-container.login-form .btn-group {
	display: flex;
	justify-content: space-between;
}

.form-container.login-form .btn-group .btn {
	width: calc(50% - 3px);
}

.form-container .btn-clear {
	background-color: #aaaaaa;
}

.form-container .btn-clear:hover {
	background-color: #949393;
}

.form-container .input-pw {
	padding-right: 35px;
}

.form-container .form-group {
	position: relative;
}

.form-group .show-pw,
.form-group .hide-pw {
	position: absolute;
	top: 9px;
	right: 11px;
	z-index: 2;
}

.form-group .hide-pw {
	display: none;
}

.link-forgot-pw a {
	color: #A20000;
	/*color: #FB8C8C;*/
	text-decoration: none;
	transition: 0.2s ease;
}

.link-forgot-pw a:hover {
	color: #EC4646;
}

@media only screen and (max-width: 640px) {
	.form-container {
		margin: 0 auto !important;
		width: calc(100% - 30px);
	}
}

@media only screen and (max-width: 400px) {
	.form-container {
		padding-left: 20px !important;
		padding-right: 20px !important;
	}
}


/*** FORGOT PASSWORD FORM ***/
.fpassword-form {
	background-color: #1F1E21;
}

.fpassword-form h3 {
	font-size: 16px;
	letter-spacing: 1px;
}

.fpassword-form p {
	font-size: 12px;
}

.fpassword-form p br {
	display: none;
}

@media only screen and (max-width: 410px) {
	.fpassword-form p br {
		display: block;
	}
}


/** RE-ENTER PASSWORD FORM **/
.re-enter-password-form {
	background-color: #1F1E21;
}

.re-enter-password-form h3 {
	font-size: 16px;
	letter-spacing: 1px;
}


/** FIRST TIME LOGIN ***/
.ftime-login-form {
	background-color: #1F1E21;
}


/** SNACKBAR **/
.notification {
	/*	width: 325px;
	font-size: 14px;
	color: #fff;
	position: fixed;
	z-index: 1;
	margin-left: auto;
	margin-right: 115px;
	right: 0;
	bottom: 50px;*/
	width: 325px;
	font-size: 14px;
	color: #fff;
	position: fixed;
	z-index: 1;
	margin-left: auto;
	/*margin-right: 130px;*/
	margin-right: 120px;
	right: 0;
	bottom: 110px;
}

.notif-content {
	display: flex;
	align-items: center;
	letter-spacing: 0px !important;
	line-height: 20px;
	justify-content: center;
}

.notif-content span {
	font-size: 12px;
}

#notificationSuccess {
	/*display: none;*/
	background-color: #219742;
}

#notificationFailed {
	/*display: none;*/
	background-color: #EC4646;
}

@media only screen and (min-width: 1920px) .notification {
	width: 270px;
}

@-webkit-keyframes fadein {
	from {
		bottom: 0;
		opacity: 0;
	}

	to {
		bottom: 30px;
		opacity: 1;
	}
}

@keyframes fadein {
	from {
		bottom: 0;
		opacity: 0;
	}

	to {
		bottom: 30px;
		opacity: 1;
	}
}

@-webkit-keyframes fadeout {
	from {
		bottom: 30px;
		opacity: 1;
	}

	to {
		bottom: 0;
		opacity: 0;
	}
}

@keyframes fadeout {
	from {
		bottom: 30px;
		opacity: 1;
	}

	to {
		bottom: 0;
		opacity: 0;
	}
}

@media only screen and (max-width: 640px) {
	.notification {
		margin-left: auto !important;
		margin-right: auto !important;
		left: 0;
	}
}

@media only screen and (max-width: 410px) {
	.notification {
		width: calc(100% - 60px);
	}
}

.button-primary {
	background-color: #FF0000 !important;
	height: 46px !important;
}

.button-primary:hover {
	background-color: #f40707 !important;
}

.inputRounded .MuiOutlinedInput-root {
	border-radius: 20px !important;
}

/* .inputRounded .MuiInputBase-adornedEnd .MuiInputBase-formControl {
	background: rgba(0, 0, 0, 0.9) !important;
} */

.inputRounded .MuiInputBase-root {
	/* background-color: transparent; */
	background-color: #454545 !important;
	/* border-radius: 10px 10px 10px 10px !important; */
	border-radius: 10px !important;

}

.inputRounded .MuiInputBase-root:hover {
	background-color: transparent;
}

.inputRounded .MuiFilledInput-input {
	border-radius: 10px !important;
}

.MuiFormHelperText-root {
	margin-left: 0 !important;
}

.MuiAppBar-root {
	top: -5px !important;
}