.modal-video-duration {
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
}

.modal-video-sort-icon {
    height: 28px;
    float: right;
    margin-top: -6px;
}

.watch-video {
    float: right;
}

.modal-video-hr {
    border: 0.5px solid gray;
}

.related-videos-play-icon {
    height: 41px !important;
}

.container1 .icon-tag-cover {
    position: absolute;
    bottom: 44%;
    left: 42%;
}

@media screen and (max-device-width: 480px) and (orientation: portrait) {
    .container1 .icon-tag-cover {
        position: absolute;
        bottom: 40%;
        left: 38%;
    }
}

.cover-video-play-button {
    height: 90px !important;
    width: auto !important;
}

.thumbnail .topic-play {
    height: 55px !important;
    width: auto !important;
}

.topic-play-v2 {
    height: 55px !important;
    width: auto !important;
}

.modal-container .close {
    z-index: 10 !important;
}

.watch-disabled {
    cursor: default !important;
}

.cursor-pointer {
    cursor: pointer;
}

.modal-multiple-login-parent {
    z-index: 1000000 !important;
}

.modal-video-sort-font {
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: #FFFFFF !important;
}

.img-thumb-overlay-v2 {
    position: relative;
    width: 100%;
    /* max-width: 400px; */
}

.video-thumb-landscape-v2 {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 5px;
}

.video-thumb-landscape-mobile-v2 {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 5px;
}

.img-icon-overlay-v2 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
}

.img-icon-v2 {
    font-size: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.video-play-button {
    height: 50px;
    width: 50px;
}