.modal-multiple-login .MuiBox-root {
    border-radius: 10px !important;
    z-index: 10000 !important;
}

.modal-multiple-login-text {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #FF0000;
}


.modal-multiple-login-message {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;

    color: #FFFFFF;
}