.reference-parent .MuiGrid-root {
    max-width: 310px !important;
    text-align: center !important;
}

.custom-position {
    padding: 10px !important;
}

.modal-reference-description {
    font-size: 12px !important;
}

.modal-reference-content {
    height: 30rem;
    overflow: scroll;
    overflow-x: hidden;
}

.reference-bg-color {
    background-color: white !important;
}

.text-black {
    color: black !important;
}

.reference-modal-close {
    width: 16px;
    height: 16px;
}

.unselectable {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.reference-bg {
    background-color: black !important;
}

.custom-searchbox-color {
    background-color: #0D0D0D !important;
}

.reference-name-label {
    font-size: 13px !important;
}